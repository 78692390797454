import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles, CardHeader, CardContent, Typography, Card, Grid, TextField } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { formattedValue } from "../../MyPerformance";
import { dataFrequencyFormat, getNumberFormat, setSearchQuery } from "../../../../helpers";
import { getTeamPerformance, setFilterView } from "../../../../ducks/game";
import { setLoading } from "../../../../ducks/loading";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
        marginTop: 8,
        marginBottom: 35,
    },
    heading: {
        '& h2': {
            color: '#363636',
            fontWeight: 600,
            lineHeight: '24px',
            fontSize: 16,
            margin: 0,
            marginBottom: 10
        }
    },
    performance: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px 1px rgba(161, 161, 161, 0.25)',
        borderRadius: '8px',
        // paddingBottom: 10
    },
    season_head: {
        background: '#EBEBEB',
        borderRadius: '8px 8px 0px 0px',
        height: 40,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 10px',
        // marginBottom: 5
    },
    season_Title: {
        '& h4': {
            color: '#000000a6',
            margin: 0,
        }
    },
    season_: {
        '& button': {
            background: '#00000014',
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 6px',
            color: '#000000a6',
            fontSize: 14
        },
        '& p': {
            color: '#000000a6',
            fontSize: 14
        }
    },
    card: {
        padding: '10px 8px',
        '& .card': {
            // width: 312,
            height: 135,
            background: '#FFFFFF',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: 4,
            padding: '0px 5px',
            cursor: 'pointer'
        },
        '& .cardTitle': {
            fontSize: 14,
            lineHeight: '20px',
            color: 'rgba(0, 0, 0, 0.9)',
            margin: '6px 0px',
            fontWeight: 600,

        },
        '& .cardContent': {
            background: 'rgba(0, 0, 0, 0.04)',
            borderRadius: 4,
            padding: '4px 8px',
            '& h1': {
                color: 'rgba(0, 0, 0, 0.9)',
                fontSize: 24,
                margin: 0,
                fontWeight: 500,
                lineHeight: '42px'
            }
        },
        '& .perform': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            '& span': {
                fontSize: 11,
                color: 'rgba(0, 0, 0, 0.5)',
            },
            '& p': {
                margin: 0,
                fontSize: 13,
                color: 'rgba(0, 0, 0, 0.9)',
            },
            '& .values': {
                textAlign: 'center'
            },
            '& .divider': {
                margin: '0px 2px'
            }
        }
    },
    cardHeader: {
        margin: '5px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .titleHdr': {
            display: 'flex',
            alignItems: 'center',
            '& .rank': {
                background: 'rgba(0, 0, 0, 0.04)',
                borderRadius: 4,
                textAlign: 'center',
                padding: '2px 5px',
                color: 'rgba(0, 0, 0, 0.9)',
                fontSize: 13,
                marginRight: 12,
                '& span': {
                    marginLeft: '-3px'
                }
            },
            '& p': {
                margin: 0
            }
        },
        '& .ColorCode': {
            margin: 0,
            width: 18,
            height: 18,
            borderRadius: 2,
        }
    },
    skeletonRoot: {
        padding: '10px 0px',
        height: 135,
        marginBottom: 150,
        "& .card": {
            bordeRadius: 8,
            padding: '10px 8px',
            borderRadius: 4,
        },
    },
    filterHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 8,
        '& .MuiInputBase-formControl input': {
            height: '17px !important'
        },
        '& label': {
            fontSize: '14px !important'
        }
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '3px 2px !important',
        width: 120,
        '& label': {
            fontSize: '14px !important'
        }
    },
}));

const MyTeamPerformanceCard = ({ history, userDetails, seasonDetails, bonusKpiStats, loading, playerKpiStats, filterView, setFilterView }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const [filterViewBy, setFilterViewBy] = useState(dataFrequencyFormat[0]);

    const skeletonLoading = (
        <Grid container spacing={1} className={classes.skeletonRoot}>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((ld, id) => (
                <Grid key={id} item lg={3} md={6} sm={6} xs={12}>
                    <Card className="card">
                        <CardHeader style={{ padding: 0 }}
                            avatar={<Skeleton variant="reactangle" width={40} height={30} style={{ borderRadius: 4 }} />}
                            title={<Skeleton height={20} width="98%" />}
                        />
                        <CardContent style={{ padding: 0 }}>
                            <Skeleton height={20} width="100%" />
                            <Skeleton height={60} width="100%" />
                        </CardContent>
                    </Card>
                </Grid>
            ))
            }
        </Grid >
    );

    const handleKpiClick = (kpi, type) => {
        setSearchQuery(history, { kpiType: type, UOM: kpi?.unit_of_measurement, unit: kpi?.unit });
        history.push(`${path}/kpi/${kpi?.kpi_id || kpi?._id}/team${history.location?.search}`);
    }

    return (
        <div className=''>
            <div className={classes.filterHeader}>
                <div className={classes.heading}>
                    <h2>Teams KPI Performance</h2>
                </div>
                <div>
                    <Autocomplete
                        id="filterViewBy"
                        options={dataFrequencyFormat}
                        getOptionLabel={(option) => option.name || ''}
                        value={filterViewBy}
                        disableClearable
                        classes={{ inputRoot: classes.autocompleteRoot }}
                        renderInput={(params) => <TextField {...params} size='small' variant="outlined" id="filterViewBy" label="Filter ViewBy" />}
                        onChange={(...args) => { setFilterViewBy(args[1]); setFilterView(args[1]) }}
                    />
                </div>
            </div>
            {loading && skeletonLoading}
            <div className={classes.performance}>
                {playerKpiStats.length !== 0 &&
                    <div className={classes.card}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div className={classes.season_head}>
                                    <h5>{`${seasonDetails?.labels_config?.kpis?.core}`}</h5>
                                </div>
                            </Grid>
                            {
                                playerKpiStats?.map((item, index) =>
                                    <Grid key={index} item lg={3} md={6} sm={6} xs={12}>
                                        <Card className='card' variant="outlined" onClick={() => handleKpiClick(item, 'CORE')}>
                                            <div className={classes.cardHeader}>
                                                <div className="titleHdr">
                                                    {item?.rank &&
                                                        <div className="rank">
                                                            {item.rank} <span>{item?.rank === 1 ? 'st' : item?.rank === 2 ? 'nd' : item?.rank === 3 ? 'rd' : item?.rank > 3 ? 'th' : null}</span>
                                                        </div>
                                                    }
                                                    <Typography className='cardTitle'> {item.kpi_name}</Typography>
                                                </div>
                                                <div className="ColorCode" style={{ background: `${item?.status_code}` }}>
                                                </div>
                                            </div>
                                            <CardContent className="cardContent">
                                                <h1>{formattedValue(userDetails, item.actual, item)}</h1>
                                                <div className="perform">
                                                    <div className="values">
                                                        <span>Target</span>
                                                        <p>{formattedValue(userDetails, item.target, item)}</p>
                                                    </div>
                                                    <Divider orientation="vertical" className="divider" flexItem />
                                                    <div className="values">
                                                        <span>Ach%</span>
                                                        <p>{item.unit_of_measurement === 'PERCENTAGE' ? '--' : `${getNumberFormat(userDetails?.countryCode, item.achievement)}%`}</p>
                                                    </div>
                                                    {
                                                        item?.achievement_plan_enabled &&
                                                        <>
                                                            <Divider orientation="vertical" className="divider" flexItem />
                                                            <div className="values">
                                                                <span>{item.frequency === 'DAILY' ? 'MTD Target' : 'WTD Target'}</span>
                                                                <p>{formattedValue(userDetails, item.planned_target, item)}</p>
                                                            </div>
                                                            <Divider orientation="vertical" className="divider" flexItem />
                                                            <div className="values">
                                                                <span>{item.frequency === 'DAILY' ? 'MTD Ach%' : 'WTD Ach%'}</span>
                                                                <p>{item.unit_of_measurement === 'PERCENTAGE' ? '--' : `${getNumberFormat(userDetails?.countryCode, item.planned_achievement)}%`}</p>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                { }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </div>
                }
                {bonusKpiStats.length !== 0 &&
                    <div className={classes.card}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div className={classes.season_head}>
                                    <h5>{`${seasonDetails?.labels_config?.kpis?.bonus}`}</h5>
                                </div>
                            </Grid>
                            {
                                bonusKpiStats?.map((item, index) =>
                                    <Grid key={index} item lg={3} md={6} sm={6} xs={12}>
                                        <Card className='card' variant="outlined" onClick={() => handleKpiClick(item, 'BONUS')}>
                                            <div className={classes.cardHeader}>
                                                <div className="titleHdr">
                                                    {item?.rank &&
                                                        <div className="rank">
                                                            {item.rank} <span>{item?.rank === 1 ? 'st' : item?.rank === 2 ? 'nd' : item?.rank === 3 ? 'rd' : item?.rank > 3 ? 'th' : null}</span>
                                                        </div>
                                                    }
                                                    <Typography className='cardTitle'> {item.kpi_name}</Typography>
                                                </div>
                                                <div className="ColorCode" style={{ background: `${item?.status_code}` }}>
                                                </div>
                                            </div>
                                            <CardContent className="cardContent">
                                                <h1>{formattedValue(userDetails, item.actual, item)}</h1>
                                                <div className="perform">
                                                    <div className="values">
                                                        <span>Target</span>
                                                        <p>{formattedValue(userDetails, item.target, item)}</p>
                                                    </div>
                                                    <Divider orientation="vertical" className="divider" flexItem />
                                                    <div className="values">
                                                        <span>Ach%</span>
                                                        <p>{item.unit_of_measurement === 'PERCENTAGE' ? '--' : `${getNumberFormat(userDetails?.countryCode, item.achievement)}%`}</p>
                                                    </div>
                                                    {
                                                        item?.achievement_plan_enabled &&
                                                        <>
                                                            <Divider orientation="vertical" className="divider" flexItem />
                                                            <div className="values">
                                                                <span>{item.frequency === 'DAILY' ? 'MTD Target' : 'WTD Target'}</span>
                                                                <p>{formattedValue(userDetails, item.planned_target, item)}</p>
                                                            </div>
                                                            <Divider orientation="vertical" className="divider" flexItem />
                                                            <div className="values">
                                                                <span>{item.frequency === 'DAILY' ? 'MTD Ach%' : 'WTD Ach%'}</span>
                                                                <p>{item.unit_of_measurement === 'PERCENTAGE' ? '--' : `${getNumberFormat(userDetails?.countryCode, item.planned_achievement)}%`}</p>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                { }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </div>
                }
            </div>
        </div >
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user,
    seasonKpiPostions: state.game.seasonKpiPostions,
    seasonDetails: state.game.seasonDetails,
    filterView: state.game.filterView
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getTeamPerformance: (options) => dispatch(getTeamPerformance(options)),
        setFilterView: (options) => dispatch(setFilterView(options))

    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyTeamPerformanceCard));