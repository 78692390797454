import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles, IconButton, Menu, MenuItem } from '@material-ui/core';
import { ArrowForward, ChevronLeft } from '@material-ui/icons';
import ShareSharpIcon from '@material-ui/icons/ShareSharp';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { toJpeg } from "html-to-image";
import { withRouter } from "react-router-dom";
import { getKpiWiseNewLeaderBoard, getKpiWisePlayerLeaderBoard, getPlayerLeaderBoard } from '../../../ducks/game';
import { openCreateArenaDialog } from '../../../ducks/arena';
import { divindentValue, downloadFiles, getDurationWithUnit, getNumberFormat, getSearchValue, numberFormater } from "../../../helpers";
import { setLoading } from "../../../ducks/loading";
import CustomDataTable from '../../GlobalComponents/CustomDataTable';
import FieldSet from '../../GlobalComponents/FieldSet';
import PlayerLeaderboardTemplate from '../../GlobalComponents/PlayerLeaderboardTemplate';
import { ReactComponent as ReactArenaLogo } from "../../../assets/icons/arena-1.svg";
import { ReactComponent as ReactChatLogo } from "../../../assets/icons/chat-1.svg";
import { ReactComponent as Share } from "../../../assets/icons/share-post.svg";
import GetCountryCurrencyFormat from '../../GlobalComponents/GetCountryCurrencyFormat';
import { CustomCarousel } from '../../Leagues/version1/CustomCarousel';




const useStyles = makeStyles((theme) => ({
    tabsRoot: {
        width: '100%',
        maxWidth: '100%',
        minHeight: 26,
        zIndex: 11,
        transition: 'top 0.2s ease-in-out',
        borderBottom: '1px solid #f0f2f5',
        marginTop: 20
    },
    tabRoot: {
        minHeight: 26,
        textTransform: 'capitalize',
        minWidth: 'auto',
        margin: '0px 8px',
        padding: '0px 15px',
        borderRadius: 13,
        backgroundColor: '#FFF',
        '&:first-child': {
            marginLeft: 0
        }
    },
    selectedTab: {
        color: '#FFF !important',
        backgroundColor: theme.palette.primary.main
    },
    disabledScrollBtn: {
        display: 'none'
    },

    legendStyles: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginLeft: 8,
            cursor: 'pointer',
            fontSize: 16

        }
    },
    filedSetRootStyles: {
        padding: '10px 20px 20px',
    },
    viewAllButton: {
        margin: '15px 2px 0px 0',
        color: 'blue', // #112386
        fontSize: 15,
        float: 'right',
        cursor: 'pointer',
        textDecoration: 'underline',
        // fontWeight: 600,
        '& svg': {
            fontSize: 18,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    },
    iconDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row'
    },
    iconOptions: {
        display: 'flex',
        flexDirection: 'column'
    },
    shareOptionStyles: {
        fontSize: 14,
        '& #share-to-wall': {
            width: 20,
            height: 20,
            marginRight: 10,
            // '& path': {
            //   fill: theme.palette.primary.main
            // },
            // '& path[fill="white"]': {
            //   fill: '#FFF',
            // },
            '& path': {
                stroke: theme.palette.primary.main
            }
        },
        '& #download': {
            width: 20,
            height: 20,
            marginRight: 10,
            '& path': {
                fill: theme.palette.primary.main
            },
            '& path[fill="white"]': {
                fill: '#FFF',
            }
            // '& path': {
            //     stroke: theme.palette.primary.main
            // },

        }
    },
    rolesList: {
        width: '100%',
        maxWidth: '70%',
        background: 'rgba(0, 0, 0, 0.06)',
        borderRadius: 4,
        height: 40,
        padding: 1,
        alignItems: 'center',
        marginBottom: 8
    },

}))

const LeaderboardTable = ({ columns = [], seasonDetails, seasonDetailsViewAll, keycloak, tabData = {}, tabType, kpis = [], dataList = [], type, filtersData, viewType = "", handleViewChange, setLoading, loading, getPlayerLeaderBoard, getKpiWisePlayerLeaderBoard, groupTab, userDetails, handleDownloadClick, openCreateArenaDialog, match, setViewPageType, roleFilterId, location, history, seasonRoles, leaderboardFilter, setLeaderboardFilter, getKpiWiseNewLeaderBoard }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const [selectedValue, setSelectedValue] = useState(null);

    const [data, setData] = useState([]);
    const [showViewAllButton, setShowViewAllButton] = useState(false);
    const [canDownload, setCanDownload] = useState(false);
    const [isDisplay, setIsDisplay] = useState(false);
    const [shareAnchorEl, setShareAnchorEl] = React.useState({
        el: null,
        arena: null
    });
    const selectedRole = seasonRoles?.find(obj => obj?._id === getSearchValue(history, 'role'))

    const isShareMenuOpen = Boolean(shareAnchorEl.el);
    const [roleObj, setRoleObj] = useState();
    // const selectRole = roles?.find(obj => obj?._id === getSearchValue(history, 'role'))

    const { enqueueSnackbar } = useSnackbar();
    const isAdmin = keycloak.decoded_token.realm_access.roles &&
        keycloak.decoded_token.realm_access.roles.length > 0 &&
        keycloak.decoded_token.realm_access.roles.includes('admin');

    const intlNumberFormat = (type, value) => {
        let props = {
            type: type,
            locale: seasonDetails?.country?.locale
        }
        if (seasonDetailsViewAll?.numaration !== 'DEFAULT') {
            const formatter = numberFormater(props, seasonDetailsViewAll?.country)
            const { divisor, label } = divindentValue(seasonDetailsViewAll?.numaration);
            if (props?.type === 'number' && value < 100000) return new Intl.NumberFormat(seasonDetailsViewAll ? seasonDetailsViewAll?.country?.locale : 'en-IN').format(Math.trunc(value))
            else return (divisor && label) ? `${props?.type === "currency" ? seasonDetailsViewAll?.country.currency_symbol : ''}${formatter.format(value / divisor)}${label}` : formatter.format(value);
        }
        else {
            if (props?.type === 'number' && value < 100000) return new Intl.NumberFormat(seasonDetailsViewAll ? seasonDetailsViewAll?.country?.locale : 'en-IN').format(Math.trunc(value))
            else return new Intl.NumberFormat(seasonDetailsViewAll ? seasonDetailsViewAll?.country?.locale : 'en-IN', {
                style: type === "currency" ? "currency" : undefined,
                notation: 'compact',
                currency: seasonDetailsViewAll ? seasonDetailsViewAll?.country?.currency_code : 'INR',
                maximumFractionDigits: 2
            }).format(value).replaceAll('T', 'K');
        }
    }


    // useEffect(() => {
    //     selectedRole && setLeaderboardFilter({ ...leaderboardFilter, roles: selectedRole });
    // }, [selectedRole])

    // useEffect(() => {
    //     if (groupTab <= 1) {
    //         setRoleObj(roles?.find(obj => obj?._id === getSearchValue(history, 'role')));
    //     }
    // }, [selectRole])

    useEffect(() => {
        if (viewType === "PAGE" && setViewPageType) {
            setViewPageType('PAGE');
        }
    }, [])
    useEffect(() => {
        if (viewType === "PAGE" && dataList.length > 0 && match.params?.id && !tabData?._id) setSelectedValue(match.params.id)
    }, [dataList]);

    useEffect(() => {
        if (viewType === "CARD" && tabData?._id) setSelectedValue(tabData)
    }, [tabData]);

    // useEffect(() => {
    //     if (selectedValue && filtersData.season && seasonDetailsViewAll) {
    //         if (groupTab <= 1) {
    //             getKpiWisePlayerLeaderBoardList(filtersData.season._id, selectedValue._id);
    //         }
    //         else if (groupTab >= 2) {
    //             getPlayerLeaderBoardList(filtersData.season._id, selectedValue._id);
    //         }
    //     }
    // }, [selectedValue, seasonDetailsViewAll._id, leaderboardFilter]);


    useEffect(() => {
        if (selectedValue) {
            getNewLeaderBoardList(filtersData.season?._id, selectedValue?._id);
        }
    }, [filtersData.season?._id, leaderboardFilter, selectedValue])

    // ----------new leaderborad -----------
    const getNewLeaderBoardList = (seasonId, kpiId) => {
        setLoading(true);
        getKpiWiseNewLeaderBoard({
            params: {
                season_id: seasonId,
                kpi_id: kpiId ? kpiId : match.params?.id,
                team_id: leaderboardFilter.teams?._id ? leaderboardFilter.teams?._id : null,
                role_id: leaderboardFilter.roles?._id ? leaderboardFilter.roles?._id : null,
                region_id: leaderboardFilter.regions?._id ? leaderboardFilter.regions?._id : null,
                division_id: leaderboardFilter.divisions?._id ? leaderboardFilter.divisions?._id : null,
                type: (location.pathname.split('/')[4] === 'core') ? 'CORE' : 'BONUS',
                limit: viewType === 'CARD' ? 5 : 5000, page: 1,
            },
            onSuccessCB: (result, headers) => {
                const dubRes = [...result].map(obj => ({
                    ...obj,
                    selected: obj.username === userDetails?.username, match_points: obj.points?.match, bonus_points: obj.points?.bonus, player: { name: obj.name, photo_url: obj.photo_url, _id: obj.username },
                    target: obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.target)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.target)
                            : `${getNumberFormat(userDetails?.countryCode, obj.target)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.target, obj.unit) : ''}`,
                    actual: obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.actual)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.actual)
                            : `${getNumberFormat(userDetails?.countryCode, obj.actual)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.actual, obj.unit) : ''}`,
                    pending_actual: obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.pending_actual)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.pending_actual)
                            : `${getNumberFormat(userDetails?.countryCode, obj.pending_actual)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.pending_actual, obj.unit) : ''}`,
                    planned_target: obj.planned_target ? obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.planned_target)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.planned_target)
                            : `${getNumberFormat(userDetails?.countryCode, obj.planned_target)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.planned_target, obj.unit) : ''}`
                        : 0,
                    planned_actual: obj.planned_actual ? obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.planned_actual)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.planned_actual)
                            : `${getNumberFormat(userDetails?.countryCode, obj.planned_actual)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.planned_actual, obj.unit) : ''}`
                        : 0,
                    planned_pending_actual: obj.planned_pending_actual ? obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.planned_pending_actual)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.planned_pending_actual)
                            : `${getNumberFormat(userDetails?.countryCode, obj.planned_pending_actual)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.planned_pending_actual, obj.unit) : ''}`
                        : 0
                }))
                setData(dubRes);
                setShowViewAllButton(parseInt(headers?.total_count) > 5 ? true : false);
                setLoading(false);
            }, onErrorCB: (error) => {
            }
        })
    }

    const getPlayerLeaderBoardList = (seasonId, tabId) => {
        setLoading(true);
        getPlayerLeaderBoard({
            params: {
                season_id: seasonId,
                team_id: groupTab === 2 ? tabId : null,
                role_id: groupTab === 3 ? tabId : ['region', 'division'].includes(location.pathname.split('/')[5]) ? (selectedRole ? (roleFilterId ? roleFilterId : null) : (viewType === "CARD" ? roleFilterId : null)) : null,
                region_id: groupTab === 4 ? tabId : null,
                division_id: groupTab === 5 ? tabId : null,
                limit: viewType === 'CARD' ? 5 : 5000, page: 1,
            },
            onSuccessCB: (result, headers) => {
                const dubRes = [...result].map(obj => ({
                    ...obj,
                    selected: obj.username === userDetails?.username, match_points: obj.points?.match, bonus_points: obj.points?.bonus, player: { name: obj.name, photo_url: obj.photo_url, _id: obj.username },
                    target: obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.target)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.target)
                            : `${getNumberFormat(userDetails?.countryCode, obj.target)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.target, obj.unit) : ''}`,
                    actual: obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.actual)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.actual)
                            : `${getNumberFormat(userDetails?.countryCode, obj.actual)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.actual, obj.unit) : ''}`,
                    pending_actual: obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.pending_actual)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.pending_actual)
                            : `${getNumberFormat(userDetails?.countryCode, obj.pending_actual)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.pending_actual, obj.unit) : ''}`,
                    planned_target: obj.planned_target ? obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.planned_target)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.planned_target)
                            : `${getNumberFormat(userDetails?.countryCode, obj.planned_target)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.planned_target, obj.unit) : ''}`
                        : 0,
                    planned_actual: obj.planned_actual ? obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.planned_actual)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.planned_actual)
                            : `${getNumberFormat(userDetails?.countryCode, obj.planned_actual)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.planned_actual, obj.unit) : ''}`
                        : 0,
                    planned_pending_actual: obj.planned_pending_actual ? obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.planned_pending_actual)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.planned_pending_actual)
                            : `${getNumberFormat(userDetails?.countryCode, obj.planned_pending_actual)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.planned_pending_actual, obj.unit) : ''}`
                        : 0
                }))
                setData(dubRes);
                setShowViewAllButton(parseInt(headers?.total_count) > 5 ? true : false);
                setLoading(false);
            }, onErrorCB: (error) => {
                // setLoading(false);
                // enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                //     variant: 'error',
                //     preventDuplicate: true
                // });
            }
        })
    }

    const getKpiWisePlayerLeaderBoardList = (seasonId, kpiId) => {
        setLoading(true);
        getKpiWisePlayerLeaderBoard({
            params: { season_id: seasonId, kpi_id: kpiId, role_id: ['core', 'bonus'].includes(location.pathname.split('/')[5]) ? (selectedRole ? (roleFilterId ? roleFilterId : null) : (viewType === "CARD" ? roleFilterId : null)) : null, type: (groupTab === 0) ? 'CORE' : 'BONUS', limit: viewType === 'CARD' ? 5 : 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                const dubRes = [...result].map(obj => ({
                    ...obj, selected: obj.username === userDetails?.username, player: { name: obj.name, photo_url: obj.photo_url, _id: obj.username }, team: { name: obj.team_name, logo_url: obj.team_logo_url, _id: obj.team_id },
                    target: obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.target)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.target)
                            : `${getNumberFormat(userDetails?.countryCode, obj.target)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.target, obj.unit) : ''}`,
                    actual: obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.actual)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.actual)
                            : `${getNumberFormat(userDetails?.countryCode, obj.actual)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.actual, obj.unit) : ''}`,
                    pending_actual: obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.pending_actual)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.pending_actual)
                            : `${getNumberFormat(userDetails?.countryCode, obj.pending_actual)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.pending_actual, obj.unit) : ''}`,
                    planned_target: obj.planned_target ? obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.planned_target)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.planned_target)
                            : `${getNumberFormat(userDetails?.countryCode, obj.planned_target)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.planned_target, obj.unit) : ''}`
                        : 0,
                    planned_actual: obj.planned_actual ? obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.planned_actual)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.planned_actual)
                            : `${getNumberFormat(userDetails?.countryCode, obj.planned_actual)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.planned_actual, obj.unit) : ''}`
                        : 0,
                    planned_pending_actual: obj.planned_pending_actual ? obj.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", obj.planned_pending_actual)
                        : obj.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", obj.planned_pending_actual)
                            : `${getNumberFormat(userDetails?.countryCode, obj.planned_pending_actual)} ${obj.unit_of_measurement === 'PERCENTAGE' ? '%' : obj.unit_of_measurement === 'DURATION' ? getDurationWithUnit(obj.planned_pending_actual, obj.unit) : ''}`
                        : 0
                }));
                setData(dubRes);
                setShowViewAllButton(parseInt(headers?.total_count) > 5 ? true : false);
                setLoading(false);
            }, onErrorCB: (error) => {
                // setLoading(false);
                // enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                //     variant: 'error',
                //     preventDuplicate: true
                // });
            }
        })
    }

    const handleShareMenuClose = () => {
        setShareAnchorEl({ el: null, arena: null });
    };

    const handleShareMenuOpen = (event) => {
        setShareAnchorEl({ el: event.currentTarget, arena: null });
    };

    const onclickDiv = (value) => {
        setLeaderboardFilter({ ...leaderboardFilter, roles: value });
    }

    const renderShareOptions = (

        <Menu
            anchorEl={shareAnchorEl.el}
            classes={{ paper: classes.profilePopup }}
            id="share-options-menu"
            keepMounted
            open={isShareMenuOpen}
            onClose={handleShareMenuClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            style={{ zIndex: 1301 }}
        >
            <MenuItem onClick={() => handleDownloadClick(tabData, data, 'download', handleShareMenuClose, tabType)} className={classes.shareOptionStyles} ><GetAppOutlinedIcon id="download" />Download</MenuItem>
            <MenuItem onClick={() => handleDownloadClick(tabData, data, 'share', handleShareMenuClose, tabType, leaderboardFilter)} className={classes.shareOptionStyles} ><ReactArenaLogo id="share-to-wall" />Share to Wall</MenuItem>
        </Menu>
    );

    // const onclickDiv = (value) => {
    //     setRoleObj(value);
    // }

    return (

        <>
            {
                (viewType === 'CARD') ?
                    <FieldSet
                        title={selectedValue?.name}
                        rootStyles={classes.filedSetRootStyles}

                    >
                        {/* {
                            groupTab <= 1 ?
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div className={classes.rolesList}>
                                        <CustomCarousel data={roles} selectesData={roleObj ? roleObj : roles[0]} onclickDiv={onclickDiv} />
                                    </div>
                                    {isAdmin && data.length > 0 ? <div className={classes.iconDiv}>
                                        <IconButton onClick={(e) => handleShareMenuOpen(e,)} >
                                            <ShareSharpIcon fontSize='small' />
                                        </IconButton>
                                    </div> : null}
                                    {renderShareOptions}
                                </div>
                                :
                                <> */}
                        {isAdmin && data.length > 0 ? <div className={classes.iconDiv}>
                            <IconButton onClick={(e) => handleShareMenuOpen(e,)} >
                                <ShareSharpIcon fontSize='small' />
                            </IconButton>
                        </div> : null}
                        {renderShareOptions}
                        {/* </>
                        } */}
                        <CustomDataTable columns={columns} data={data} options={{ pagination: false }} rootProps={{ style: { marginTop: 20 } }} />
                        {showViewAllButton &&
                            <>
                                <span className={classes.viewAllButton} onClick={() => handleViewChange('PAGE', tabData, type, roleFilterId)}>View all<ArrowForward /></span>
                            </>
                        }
                    </FieldSet>
                    :
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                            <IconButton title="Back" className={classes.backButton} size="small" onClick={() => handleViewChange('CARD', null, type)}><ChevronLeft /></IconButton>
                            {<p style={{ margin: '0px 0px 0px 7px', color: '#000000c9', fontWeight: 600 }}>{tabType} : {selectedValue?.name?.toUpperCase()}</p>}
                        </div>
                        {/* {selectedRole && ['core', 'bonus'].includes(location.pathname.split('/')[5]) &&
                            <div className={classes.rolesList} style={{ marginTop: 20, maxWidth: '60%' }}>
                                <CustomCarousel data={seasonRoles} selectesData={leaderboardFilter?.roles ? leaderboardFilter?.roles : seasonRoles[0]} onclickDiv={onclickDiv} />
                            </div>
                        } */}
                        <CustomDataTable columns={columns(tabData)} data={data} rootProps={{ style: { marginTop: 10 } }} loading={loading} />
                    </>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    keycloak: state.keycloak,
    seasonDetailsViewAll: state.game.seasonDetails

})

const mapDispatchToProps = (dispatch, props) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getPlayerLeaderBoard: (options) => dispatch(getPlayerLeaderBoard(options)),
        getKpiWisePlayerLeaderBoard: (options) => dispatch(getKpiWisePlayerLeaderBoard(options)),
        openCreateArenaDialog: (options) => dispatch(openCreateArenaDialog(options)),
        getKpiWiseNewLeaderBoard: (options) => dispatch(getKpiWiseNewLeaderBoard(options)),
    }
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LeaderboardTable));